import React from "react";
import PropTypes from "prop-types";
import Section from "~components/atoms/section/section";
import HowItWorks from "~components/organisms/how_it_works/how_it_works";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionHowItWorks({
  howItWorks: howItWorksArray,
  id,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [howItWorks] = howItWorksArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (howItWorks) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <HowItWorks {...howItWorks} />
      </Section>
    );
  }

  return null;
}

SectionHowItWorks.propTypes = {
  howItWorks: BLOK_ARRAY,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
};

SectionHowItWorks.defaultProps = {
  howItWorks: null,
  id: null,
  sectionSettings: null,
};

export default SectionHowItWorks;
