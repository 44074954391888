import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Video from "~components/molecules/video/video/video";
import { subscribe, unsubscribe } from "../../../event_system";
import { MEDIA } from "../../../prop_types";

export default function AccordionVideo({
  videos,
  width,
  eventNamespace,
  poster,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (eventNamespace) {
      subscribe(`${eventNamespace}_index`, setActiveIndex);

      return () => {
        unsubscribe(eventNamespace);
      };
    }
  }, [eventNamespace]);

  const video = videos[activeIndex];

  return <Video poster={poster} video={video} width={width} />;
}

AccordionVideo.defaultProps = {
  videos: null,
  width: null,
  poster: null,
  eventNamespace: null,
};

AccordionVideo.propTypes = {
  videos: PropTypes.arrayOf(MEDIA),
  poster: MEDIA,
  width: PropTypes.string,
  eventNamespace: PropTypes.string,
};
