// extracted by mini-css-extract-plugin
export var bgImageLeftStyle = "SectionFrequentlyAskedQuestions-module--bgImageLeftStyle--qoB55";
export var bgImageRightStyle = "SectionFrequentlyAskedQuestions-module--bgImageRightStyle--mqv62";
export var content = "SectionFrequentlyAskedQuestions-module--content--DCWOV";
export var disabledStyle = "SectionFrequentlyAskedQuestions-module--disabledStyle--JVJKE";
export var icon = "SectionFrequentlyAskedQuestions-module--icon--XDsL7";
export var item = "SectionFrequentlyAskedQuestions-module--item--ko0lV";
export var richText = "SectionFrequentlyAskedQuestions-module--richText--FxP2u";
export var root = "SectionFrequentlyAskedQuestions-module--root--LPKiT";
export var slideDown = "SectionFrequentlyAskedQuestions-module--slideDown--Cv7mf";
export var slideUp = "SectionFrequentlyAskedQuestions-module--slideUp--I8uv7";
export var trigger = "SectionFrequentlyAskedQuestions-module--trigger--czNmf";