import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Section from "~components/atoms/section/section";
import QuoteLargeCenterAligned from "~components/molecules/quote/quote_large_center_aligned/quote_large_center_aligned";
import { BLOK_ARRAY } from "../../../prop_types";

function SectionQuoteBanner({
  id,
  quote: quoteArray,
  sectionSettings: sectionSettingsArray,
  ...rest
}) {
  const [quote] = quoteArray || [];
  const [sectionSettings] = sectionSettingsArray || [];

  if (quote) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <Contain>
          <QuoteLargeCenterAligned {...quote} />
        </Contain>
      </Section>
    );
  }

  return null;
}

SectionQuoteBanner.propTypes = {
  id: PropTypes.string,
  quote: BLOK_ARRAY,
  sectionSettings: BLOK_ARRAY,
};

SectionQuoteBanner.defaultProps = {
  id: null,
  quote: null,
  sectionSettings: null,
};

export default SectionQuoteBanner;
