import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SectionBlogArticleListVrp from "~components/molecules/blog_article_list/section_blog_article_list_vrp";
import SectionFrequentlyAskedQuestions from "~components/sections/SectionFrequentlyAskedQuestions/SectionFrequentlyAskedQuestions";
import SectionAlternatingLayout from "~components/sections/section_alternating_layout/section_alternating_layout";
import SectionContactForm from "~components/sections/section_contact_form/section_contact_form";
import SectionFeatureGrid from "~components/sections/section_feature_grid/section_feature_grid";
import SectionHeroWrapper from "~components/sections/section_hero_wrapper/section_hero_wrapper";
import SectionHowItWorks from "~components/sections/section_how_it_works/section_how_it_works";
import SectionImageGrid from "~components/sections/section_image_grid/section_image_grid";
import SectionLargeVideoPlayer from "~components/sections/section_large_video_player/section_large_video_player";
import SectionQuoteBanner from "~components/sections/section_quote_banner/section_quote_banner";
import SectionStats from "~components/sections/section_stats/section_stats";
import renderDynamicComponent from "~components/utils/render_dynamic_component/render_dynamic_component";
import usePageData from "~hooks/use_page_data/use_page_data";
import { STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";

const TEMPLATE_COMPONENTS = {
  sectionAlternatingLayout: SectionAlternatingLayout,
  sectionContactForm: SectionContactForm,
  sectionFeatureGrid: SectionFeatureGrid,
  sectionHeroWrapper: SectionHeroWrapper,
  sectionBlogArticleListVrp: SectionBlogArticleListVrp,
  sectionHowItWorks: SectionHowItWorks,
  sectionImageGrid: SectionImageGrid,
  sectionLargeVideoPlayer: SectionLargeVideoPlayer,
  sectionQuoteBanner: SectionQuoteBanner,
  sectionStats: SectionStats,
  sectionFrequentlyAskedQuestions: SectionFrequentlyAskedQuestions,
};

export default function TemplateProductPage(props) {
  const { story, pageContext } = usePageData(props);

  const { children } = story;
  const { subNavData, lang } = pageContext;

  return (
    <TemplateGlobalEntry
      headerColor="white"
      lang={lang}
      subNavData={subNavData}
      {...props}
    >
      {children?.length > 0 &&
        children.map((childComponent) => {
          return renderDynamicComponent({
            parentComponentName: "TemplateProductPage",
            componentName: childComponent.component,
            componentsMap: TEMPLATE_COMPONENTS,
            ...childComponent,
          });
        })}
    </TemplateGlobalEntry>
  );
}

TemplateProductPage.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

TemplateProductPage.defaultProps = {
  pageContext: {
    subNavData: [],
  },
};
// TODO: get page specific metadata here
export const query = graphql`
  query TemplateProductPageQuery($id: String, $langRegex: String) {
    ...story
    ...global
  }
`;
