import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import BackgroundImage from "~components/molecules/background_image/background_image";
import PhoneMockup from "~components/molecules/phone_mockup/phone_mockup";
import { BLOK_ARRAY, COLOR, MEDIA } from "../../../prop_types";
import AccordionList from "../accordion_list/accordion_list";
import AccordionVideo from "../accordion_video/accordion_video";
import {
  contentWrapperStyle,
  copyWrapperStyle,
  featureWrapperStyle,
  headingWrapperStyle,
  isReversedStyle,
} from "./how_it_works.module.scss";

export default function HowItWorks({
  productName,
  title,
  subtitle,
  videos,
  accordionList: accordionListArray,
  phoneMockupSettings,
  backgroundImage,
  headingColor,
  productNameColor,
  isReversed,
}) {
  const [accordionList] = accordionListArray;

  const contentClassNames = classNames(contentWrapperStyle, {
    [isReversedStyle]: isReversed,
  });

  return (
    <Contain>
      <header className={headingWrapperStyle}>
        {productName && (
          <Heading
            isUppercase
            color={productNameColor}
            seoLevel="3"
            visualLevel={7}
            balanceText
          >
            {productName}
          </Heading>
        )}
        {title && (
          <Heading
            balanceText
            color={headingColor}
            seoLevel="4"
            visualLevel="2"
          >
            {title}
          </Heading>
        )}
        {subtitle && (
          <Heading
            balanceText
            color={headingColor}
            seoLevel="5"
            visualLevel={5}
          >
            {subtitle}
          </Heading>
        )}
      </header>
      <div className={contentClassNames}>
        <div className={featureWrapperStyle}>
          <BackgroundImage
            objectFit="cover"
            width="600"
            horizontalAlign="center"
            verticalAlign="middle"
            image={backgroundImage}
          />

          {videos?.length > 0 && (
            <PhoneMockup settings={phoneMockupSettings}>
              {videos.map((video) => {
                return <AccordionVideo {...video} key={video._uid} />;
              })}
            </PhoneMockup>
          )}
        </div>
        <div className={copyWrapperStyle}>
          <div>{accordionList && <AccordionList {...accordionList} />}</div>
        </div>
      </div>
    </Contain>
  );
}

HowItWorks.defaultProps = {
  productName: null,
  title: null,
  subtitle: null,
  videos: null,
  accordionList: null,
  phoneMockupSettings: null,
  backgroundImage: null,
  headingColor: COLOR,
  isReversed: false,
  productNameColor: null,
};

HowItWorks.propTypes = {
  productName: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  videos: PropTypes.arrayOf(MEDIA),
  accordionList: BLOK_ARRAY,
  phoneMockupSettings: BLOK_ARRAY,
  backgroundImage: MEDIA,
  headingColor: COLOR,
  productNameColor: COLOR,

  isReversed: PropTypes.bool,
};
