import { graphql, useStaticQuery } from "gatsby";

// ToDo: Find a way not to hardcode language regex here

export default function useQueryArticlesVrp() {
  return useStaticQuery(
    graphql`
      {
        posts: allStoryblokEntry(
          filter: {
            field_primaryCategory_string: { eq: "recurring-payments" }
            field_component: { eq: "blogPost" }
            full_slug: { regex: "/^en-gb//" }
          }
          sort: { fields: field_date_string, order: DESC }
          limit: 3
        ) {
          nodes {
            assets {
              id
              publicURL
              fields {
                aspectRatio
              }
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
            id
            content
            full_slug
          }
        }
      }
    `
  );
}
