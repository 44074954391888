import React from "react";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import CtaResponsive from "~components/molecules/cta_responsive/cta_responsive";
import RichText from "~components/molecules/rich_text/rich_text";
import Video from "~components/molecules/video/video/video";
import { BLOK_ARRAY } from "../../../prop_types";
import { videoWrapperStyle } from "./section_large_video_player.module.scss";

function SectionLargeVideoPlayer({
  bodyRichText: bodyRichTextArray,
  cta: ctaArray,
  heading,
  id,
  sectionSettings: sectionSettingsArray,
  video: videoArray,
  ...rest
}) {
  const [bodyRichText] = bodyRichTextArray || [];
  const [cta] = ctaArray || [];
  const [sectionSettings] = sectionSettingsArray || [];
  const [video] = videoArray || [];

  if (video) {
    return (
      <Section id={id} sectionSettings={sectionSettings} {...rest}>
        <Contain small>
          {/* ————— HEADING ————— */}
          {heading && (
            <Heading
              {...rest}
              balanceText
              color="white"
              isCentered
              seoLevel={4}
              visualLevel={2}
            >
              {heading}
            </Heading>
          )}
          {/* ————— BODY TEXT ————— */}
          {bodyRichText && (
            <RichText {...bodyRichText} isCentered color="white" />
          )}
        </Contain>
        {/* ————— VIDEO ————— */}
        <Contain>
          <div className={videoWrapperStyle}>
            <Video {...video} />
          </div>
          {/* ———— CTA ————— */}
          {cta && <CtaResponsive {...cta} isCentered />}
        </Contain>
      </Section>
    );
  }
  return null;
}

SectionLargeVideoPlayer.propTypes = {
  bodyRichText: BLOK_ARRAY,
  cta: BLOK_ARRAY,
  heading: PropTypes.string,
  id: PropTypes.string,
  sectionSettings: BLOK_ARRAY,
  subheading: PropTypes.string,
  video: BLOK_ARRAY,
};

SectionLargeVideoPlayer.defaultProps = {
  bodyRichText: null,
  cta: null,
  heading: null,
  id: null,
  sectionSettings: null,
  subheading: null,
  video: null,
};

export default SectionLargeVideoPlayer;
