/* eslint-disable no-param-reassign */
import { useEffect, useRef } from "react";

import useDidMountEffect from "~hooks/use_did_mount_effect/use_did_mount_effect";

function onTransitionEnd() {
  this.style.height = null;
  this.removeEventListener("transitionend", onTransitionEnd);
}

// Trigger collapse transition
function collapseElement(el) {
  el.removeEventListener("transitionend", onTransitionEnd);

  const sectionHeight = el.scrollHeight;

  const elementTransition = el.style.transition;
  el.style.transition = "";

  requestAnimationFrame(() => {
    el.style.height = `${sectionHeight}px`;
    el.style.transition = elementTransition;

    requestAnimationFrame(() => {
      el.style.height = "0px";
    });
  });
}

// Trigger expand transition
function expandElement(el) {
  const sectionHeight = el.scrollHeight;

  // Nest in 2x rAFs to sync collapse and expand animations
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      el.style.height = `${sectionHeight}px`;
      el.addEventListener("transitionend", onTransitionEnd);
    });
  });
}

export default function useAccordionTransitions(isActive) {
  const ref = useRef(null);

  // Set initial appearance based on `isActive` state on initial mount
  useEffect(() => {
    ref.current.style.height = isActive ? null : "0px";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Once mounted, trigger passed effect on `isActive` change
  useDidMountEffect(() => {
    if (isActive) {
      expandElement(ref.current);
    } else {
      collapseElement(ref.current);
    }
  }, [isActive]);

  return ref;
}
