import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import { StaticImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import Heading from "~components/atoms/heading/heading";
import Section from "~components/atoms/section/section";
import RichText from "~components/molecules/rich_text/rich_text";
import { BLOK_ARRAY } from "../../../prop_types";
import * as styles from "./SectionFrequentlyAskedQuestions.module.scss";

function SectionFrequentlyAskedQuestions({
  subscriptTitle,
  title,
  questions,
  sectionSettings: sectionSettingsArray,
}) {
  const [sectionSettings] = sectionSettingsArray || [];

  return (
    <Section sectionSettings={sectionSettings} id="faq">
      <StaticImage
        className={styles.bgImageLeftStyle}
        src="../../../../static/images/resource_hub/in_page_contact_form_left-2x.png"
        alt="Background image"
        placeholder="none"
      />
      <StaticImage
        className={styles.bgImageRightStyle}
        src="../../../../static/images/resource_hub/in_page_contact_form_right-2x.png"
        alt="Background image"
        placeholder="none"
      />

      <Contain>
        {subscriptTitle && (
          <Heading
            color="white"
            opacity={0.5}
            isCentered
            isUppercase
            seoLevel={3}
            visualLevel={7}
          >
            {subscriptTitle}
          </Heading>
        )}
        {title && (
          <Heading
            color="white"
            isCentered
            seoLevel={subscriptTitle ? 4 : 3}
            visualLevel={2}
          >
            {title}
          </Heading>
        )}

        {questions && (
          <Accordion.Root className={styles.root} type="single" collapsible>
            {questions.map((questionItem) => {
              const [answerRichText] = questionItem.answer || [];
              return (
                <Accordion.Item
                  className={styles.item}
                  value={questionItem.question}
                >
                  <Accordion.Header>
                    <Accordion.Trigger className={styles.trigger}>
                      {questionItem.question}
                      <svg className={styles.icon}>
                        <use xlinkHref="/images/icon_plus.svg#icon" />
                      </svg>
                    </Accordion.Trigger>
                  </Accordion.Header>
                  <Accordion.Content className={styles.content}>
                    <div className={styles.richText}>
                      <RichText {...answerRichText} />
                    </div>
                  </Accordion.Content>
                </Accordion.Item>
              );
            })}
          </Accordion.Root>
        )}
      </Contain>
    </Section>
  );
}

SectionFrequentlyAskedQuestions.propTypes = {
  subscriptTitle: PropTypes.string,
  title: PropTypes.string,
  questions: BLOK_ARRAY,
  sectionSettings: BLOK_ARRAY,
};

SectionFrequentlyAskedQuestions.defaultProps = {
  subscriptTitle: null,
  title: null,
  questions: null,
  sectionSettings: null,
};

export default SectionFrequentlyAskedQuestions;
